import { SearchEngines } from "../../Promotion/constants/constants";

const specificationsEnum = {
  facebookAdTypes: {
    Single: 105,
    Carousel: 106
  },
  carouselAdKeys: {
    Carousel: 'Carousel',
    Cards: 'Cards',
    SeeMoreURL: 'SeeMoreURL',
    Link: 'Link',
    Type: 'Type'
  },
  SearchEngines
}

export { specificationsEnum };
