import * as fbEnums from "../../CampaignLauncher/Constants/FacebookEnums";

export const PromotionStatus = {
  draft: 1,
  enabled: 2,
  deleted: 3,
  disabled: 4
};
export const AcceptedInstagramMediaFormats = [
  "image/jpeg",
  "image/jpg",
  "video/mp4"
]
export const AssetType = {
  hq: 1,
  local: 2
};
export const PublisherLabel = {
  Instagram: "Instagram",
  Facebook: "Facebook",
  GMB: "Google Business"
}
export const MediaAssetType = {
  Image: 1,
  Video: 2
};
export const MediaTypeLabel = {
  Image: "IMG",
  Video: "VID"
}
export const GoogleCallToAction = [
  { label: "None", value: "NONE" },
  { label: "Book", value: "BOOK" },
  { label: "Order online", value: "ORDER" },
  { label: "Buy", value: "SHOP" },
  { label: "Learn more", value: "LEARN_MORE" },
  { label: "Sign up", value: "SIGN_UP" }
];

export const FacebookCallToActionType = {
  None: "NONE",
  BuyNow: "BUY_NOW",
  LearnMore: "LEARN_MORE",
  CallNow: "CALL_NOW"
};

export const GoogleMacros = {
  Message: [
    { name: "Store Name", value: "{{store.name}}" },
    { name: "Store Address", value: "{{store.address}}" },
    { name: "Store Postal Code", value: "{{store.postalCode}}" }
  ],
  Url: [{ name: "Store Website", value: "{{store.website}}" }]
}
export const FacebookMacros = [
  {
    name: "Store Name",
    value: "{{store.name}}"
  },
  {
    name: "Store Address",
    value: "{{store.address}}"
  },
  {
    name: "Store PostalCode",
    value: "{{store.postalCode}}"
  },
  {
    name: "Store Phone",
    value: "{{store.phoneNumber}}"
  },
  {
    name: "Store Website",
    value: "{{store.website}}"
  }
];
export const InstagramMacros = {
  Message: [
    { name: "Store Name", value: "{{store.name}}" },
    { name: "Store Address", value: "{{store.address}}" },
    { name: "Store Postal Code", value: "{{store.postalCode}}" },
    { name: "Store Phone", value: "{{store.phoneNumber}}" }
  ]
};

export const GoogleLocalPostTypes = {
  Post: 1,
  Offer: 2,
  Event: 3
};

export const FacebookPagePostTypes = {
  Link: 1,
  Image: 2,
  Video: 3
};

export const InstagramPagePostTypes = {
  Feed: 1,
  Carousel: 2,
  Reels: 3,
  Stories: 4
};
export const InstagramCreativeTypes = {
  Image: 1,
  Video: 2
};

export const deatinationTypes = {
  Website: {
    enum: fbEnums.destinationType.Page,
    objectives: [fbEnums.objectives.Conversions, fbEnums.objectives.Traffic]
  },
  "Click to Messenger": {
    enum: fbEnums.destinationType.Messenger,
    objectives: [
      fbEnums.objectives.Conversions,
      fbEnums.objectives.Traffic,
      fbEnums.objectives.Messages
    ]
  },
  WhatsApp: {
    enum: fbEnums.destinationType.WhatsApp,
    objectives: [
      fbEnums.objectives.Conversions,
      fbEnums.objectives.Traffic,
      fbEnums.objectives.Messages
    ]
  }
};

export const StoreType = {
  selective: 1,
  all: 2,
  whatsappEnabled: 3,
  whatsappDisabled: 4
};

export const PromotionType = {
  Paid: 1,
  Organic: 2
};

export const ScheduleType = {
  AlwaysOn: 1,
  Businesshour: 2,
  Custom: 3
};

export const BudgetType = {
  Daily: 1,
  LifeTime: 2
};

export const FundingSource = {
  Hq: 1,
  Local: 2,
  CoFunded: 3
};

export const VideoProcessingStatus = {
  New: 1,
  Exported: 8,
  Failed: 10
};

export const SearchEngines = {
  Facebook: 5
};

export const Publishers = {
  Facebook: 5,
  GMB: 38,
  Instagram: 50
}
export const InstagramPreviewFormat = {
  Feed: "INSTAGRAM_STANDARD",
  Carousel: "INSTAGRAM_STANDARD",
  Reels: "INSTAGRAM_REELS",
  Stories: "INSTAGRAM_STORY"
}
export const Assets = [{
  id: 1,
  label: "Asset 1",
  value: "{{store.Asset1}}"
}, {
  id: 2,
  label: "Asset 2",
  value: "{{store.Asset2}}"
}, {
  id: 3,
  label: "Asset 3",
  value: "{{store.Asset3}}"
}, {
  id: 4,
  label: "Asset 4",
  value: "{{store.Asset4}}"
}, {
  id: 5,
  label: "Asset 5",
  value: "{{store.Asset5}}"
}, {
  id: 6,
  label: "Asset 6",
  value: "{{store.Asset6}}"
}, {
  id: 7,
  label: "Asset 7",
  value: "{{store.Asset7}}"
}, {
  id: 8,
  label: "Asset 8",
  value: "{{store.Asset8}}"
}, {
  id: 9,
  label: "Asset 9",
  value: "{{store.Asset9}}"
}, {
  id: 10,
  label: "Asset 10",
  value: "{{store.Asset10}}"
}, {
  id: 11,
  label: "Asset 11",
  value: "{{store.Asset11}}"
}, {
  id: 12,
  label: "Asset 12",
  value: "{{store.Asset12}}"
}, {
  id: 13,
  label: "Asset 13",
  value: "{{store.Asset13}}"
}, {
  id: 14,
  label: "Asset 14",
  value: "{{store.Asset14}}"
}, {
  id: 15,
  label: "Asset 15",
  value: "{{store.Asset15}}"
}, {
  id: 16,
  label: "Asset 16",
  value: "{{store.Asset16}}"
}, {
  id: 17,
  label: "Asset 17",
  value: "{{store.Asset17}}"
}, {
  id: 18,
  label: "Asset 18",
  value: "{{store.Asset18}}"
}, {
  id: 19,
  label: "Asset 19",
  value: "{{store.Asset19}}"
}, {
  id: 20,
  label: "Asset 20",
  value: "{{store.Asset20}}"
}, {
  id: 21,
  label: "Asset 21",
  value: "{{store.Asset21}}"
}, {
  id: 22,
  label: "Asset 22",
  value: "{{store.Asset22}}"
}, {
  id: 23,
  label: "Asset 23",
  value: "{{store.Asset23}}"
}, {
  id: 24,
  label: "Asset 24",
  value: "{{store.Asset24}}"
}, {
  id: 25,
  label: "Asset 25",
  value: "{{store.Asset25}}"
}, {
  id: 26,
  label: "Asset 26",
  value: "{{store.Asset26}}"
}, {
  id: 27,
  label: "Asset 27",
  value: "{{store.Asset27}}"
}, {
  id: 28,
  label: "Asset 28",
  value: "{{store.Asset28}}"
}, {
  id: 29,
  label: "Asset 29",
  value: "{{store.Asset29}}"
}, {
  id: 30,
  label: "Asset 30",
  value: "{{store.Asset30}}"
}, {
  id: 31,
  label: "Asset 31",
  value: "{{store.Asset31}}"
}, {
  id: 32,
  label: "Asset 32",
  value: "{{store.Asset32}}"
}, {
  id: 33,
  label: "Asset 33",
  value: "{{store.Asset33}}"
}, {
  id: 34,
  label: "Asset 34",
  value: "{{store.Asset34}}"
}, {
  id: 35,
  label: "Asset 35",
  value: "{{store.Asset35}}"
}, {
  id: 36,
  label: "Asset 36",
  value: "{{store.Asset36}}"
}, {
  id: 37,
  label: "Asset 37",
  value: "{{store.Asset37}}"
}, {
  id: 38,
  label: "Asset 38",
  value: "{{store.Asset38}}"
}, {
  id: 39,
  label: "Asset 39",
  value: "{{store.Asset39}}"
}, {
  id: 40,
  label: "Asset 40",
  value: "{{store.Asset40}}"
}, {
  id: 41,
  label: "Asset 41",
  value: "{{store.Asset41}}"
}, {
  id: 42,
  label: "Asset 42",
  value: "{{store.Asset42}}"
}, {
  id: 43,
  label: "Asset 43",
  value: "{{store.Asset43}}"
}, {
  id: 44,
  label: "Asset 44",
  value: "{{store.Asset44}}"
}, {
  id: 45,
  label: "Asset 45",
  value: "{{store.Asset45}}"
}, {
  id: 46,
  label: "Asset 46",
  value: "{{store.Asset46}}"
}, {
  id: 47,
  label: "Asset 47",
  value: "{{store.Asset47}}"
}, {
  id: 48,
  label: "Asset 48",
  value: "{{store.Asset48}}"
}, {
  id: 49,
  label: "Asset 49",
  value: "{{store.Asset49}}"
}, {
  id: 50,
  label: "Asset 50",
  value: "{{store.Asset50}}"
}
]

export const Components = {
  PromotionConfig: "PromotionConfig",
  GMBConfig: "GoogleConfig",
  FacebookConfig: "FacebookConfig",
  PreviewPromotion: "PreviewPromotion",
  InstagramConfig: "InstagramConfig"
}

export const LaunchTestStatus = {
  NotTested: 1,
  Tested: 2,
  Error: 3
}

export const MaxInstagramStoryCapacityCount = 100
export const MaxInstagramCarouselCapacityCount = 10

export const OrganicPromoScheduleType = {
  Now: 1,
  Later: 2
}
