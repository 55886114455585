import { adwordsEnums } from "../../Constants/AdwordsEnums.js";
import { getAssetRequirements } from "../../Constants/AdwordsAdsheetSettingsRequirements.js"

class AdwordsConfig {
  constructor (Config) {
    this.config = Config;
  }
  getConfig () {
    let targetsConfig = {};
    let adGroups = new function () {
      this.base = ["valid", "campaign", "adgroup", "adExtensions", "negativeKeywordList"];
      this.requiredBase = ["campaign", "adgroup"];
      this.card = [];
      this.requiredCard = [];
    }();
    let keywords = new function () {
      this.base = ["valid", "campaign", "adgroup", "keyword", "matchType"];
      this.requiredBase = ["campaign", "adgroup", "keyword", "matchType"];
      this.card = [];
      this.requiredCard = [];
    }();
    let responsiveSearchAd = new function () {
      this.base = ["valid", "campaign", "adgroup", "finalUrls", "displayPath1", "displayPath2", "headline1", "headline2", "headline3", "headline4", "headline5", "headline6", "headline7", "headline8", "headline9", "headline10", "headline11", "headline12", "headline13", "headline14", "headline15", "hPosition1", "hPosition2", "hPosition3", "description1", "description2", "description3", "description4", "dPosition1", "dPosition2", "trackingTemplateUrl", "finalMobileUrl", "leadFormId"];
      this.requiredBase = ["campaign", "adgroup", "finalUrls", "headline1", "headline2", "headline3", "description1", "description2"];
      this.card = [];
      this.requiredCard = [];
    }();
    let expandedTextAd = new function () {
      this.base = ["valid", "campaign", "adgroup", "finalUrls", "displayPath1", "displayPath2", "headline1", "headline2", "headline3", "description1", "description2", "trackingTemplateUrl", "finalMobileUrl"];
      this.requiredBase = ["campaign", "adgroup", "finalUrls", "headline1", "headline2", "description1"];
      this.card = [];
      this.requiredCard = [];
    }();
    let callAd = new function () {
      this.base = ["valid", "campaign", "adgroup", "finalUrls", "displayPath1", "displayPath2", "countryCode", "phoneNumber", "headline1", "headline2", "description1", "description2", "trackingTemplateUrl", "finalMobileUrl", "verificationUrl", "businessName", "conversionAction"];
      this.requiredBase = ["campaign", "adgroup", "headline1", "description1", "headline2", "finalUrls", "phoneNumber", "countryCode", "businessName"];
      this.card = [];
      this.requiredCard = [];
    }();
    let assetGroups = new function () {
      this.base = ["valid", "lineItem", "name", "finalUrl", "callToAction", "adExtensions", "searchThemes", "firstPartyAudienceIds", "inMarketInterestIds", "customAudienceIds", "leadFormId", "headline1", "headline2", "headline3", "longHeadline1", "shortDescription", "description1", "businessName", "marketingImage1", "squareMarketingImage1", "logo1", "youtubeVideos"];
      this.requiredBase = ["name", "finalUrl", "headline1", "headline2", "headline3", "longHeadline1", "shortDescription", "description1", "businessName", "marketingImage1", "squareMarketingImage1", "logo1"];
      this.card = [];
      this.requiredCard = [];
      this.isAdsheetSettingSupported = true
    }();
    let responsiveDisplayAd = new function () {
      this.base = ["valid", "campaign", "adgroup", "finalUrl", "headline1", "longHeadline", "description1", "businessName", "marketingImage1", "squareMarketingImage1", "logo1", "squareLogo1", "youtubeVideo1"];
      this.requiredBase = ["campaign", "adgroup", "finalUrl", "headline1", "longHeadline", "description1", "businessName", "marketingImage1", "squareMarketingImage1"];
      this.card = [];
      this.requiredCard = [];
      this.isAdsheetSettingSupported = true
    }();
    let appAds = new function () {
      this.base = ["valid", "campaign", "adgroup", "headline1", "headline2", "description1", "image1", "youtubeVideo1"];
      this.requiredBase = ["campaign", "adgroup", "headline1", "headline2", "description1"];
      this.card = [];
      this.requiredCard = [];
      this.isAdsheetSettingSupported = true
    }();
    let appEngagementAds = new function () {
      this.base = ["valid", "campaign", "adgroup", "headline1", "headline2", "description1", "appUrl", "image1", "youtubeVideo1"];
      this.requiredBase = ["campaign", "adgroup", "headline1", "headline2", "description1", "appUrl"];
      this.card = [];
      this.requiredCard = [];
      this.isAdsheetSettingSupported = true
    }();
    let discoveryImageAds = new function () {
      this.base = ["valid", "campaign", "adgroup", "name", "finalUrl", "headline1", "description1", "businessName", "marketingImage1", "squareMarketingImage1", "logo1", "callToAction", "portraitMarketingImage1"];
      this.requiredBase = ["campaign", "adgroup", "name", "finalUrl", "headline1", "description1", "businessName", "marketingImage1", "squareMarketingImage1", "logo1"];
      this.card = [];
      this.requiredCard = [];
      this.isAdsheetSettingSupported = true
    }();
    let discoveryVideoAds = new function () {
      this.base = ["valid", "campaign", "adgroup", "name", "finalUrl", "headline1", "longHeadline1", "description1", "businessName", "logo1", "video1", "callToAction"];
      this.requiredBase = ["campaign", "adgroup", "name", "finalUrl", "headline1", "longHeadline1", "description1", "businessName", "logo1", "video1"];
      this.card = [];
      this.requiredCard = [];
      this.isAdsheetSettingSupported = true
    }();
    let discoveryCarouselAds = new function () {
      this.base = ["valid", "campaign", "adgroup", "name", "finalUrl", "headline", "description", "businessName", "logo", "card1MarketingImage", "card1SquareMarketingImage", "card1PortraitMarketingImage", "card1CallToAction", "card1Headline", "card1FinalURL", "card2MarketingImage", "card2SquareMarketingImage", "card2PortraitMarketingImage", "card2CallToAction", "card2Headline", "card2FinalURL"];
      this.requiredBase = ["campaign", "adgroup", "name", "finalUrl", "headline", "description", "businessName", "logo", "card1Headline", "card1FinalURL", "card1MarketingImage", "card1SquareMarketingImage", "card2MarketingImage", "card2SquareMarketingImage", "card2Headline", "card2FinalURL"];
      this.card = [];
      this.requiredCard = [];
      this.isAdsheetSettingSupported = true
    }();
    targetsConfig[adwordsEnums.adwordsAdFormats[1][0]] = adGroups;
    targetsConfig[adwordsEnums.adwordsAdFormats[1][1]] = keywords;
    targetsConfig[adwordsEnums.adwordsAdFormats[1][2]] = responsiveSearchAd;
    targetsConfig[adwordsEnums.adwordsAdFormats[1][3]] = callAd;
    targetsConfig[adwordsEnums.adwordsAdFormats[1][4]] = expandedTextAd;
    targetsConfig[adwordsEnums.adwordsAdFormats[7][0]] = assetGroups;
    targetsConfig[adwordsEnums.adwordsAdFormats[2][1]] = responsiveDisplayAd;
    targetsConfig[adwordsEnums.adwordsAdFormats[6][1]] = appAds;
    targetsConfig[adwordsEnums.adwordsAdFormats[6][2]] = appEngagementAds;
    targetsConfig[adwordsEnums.adwordsAdFormats[8][1]] = discoveryCarouselAds;
    targetsConfig[adwordsEnums.adwordsAdFormats[8][2]] = discoveryImageAds;
    targetsConfig[adwordsEnums.adwordsAdFormats[8][3]] = discoveryVideoAds;

    return targetsConfig;
  }

  displayHeaderNameLookup () {
    let returnObj = {
      "campaign": "Campaign",
      "adgroup": "Adgroup",
      "negativeKeywordList": "Negative Keyword List",
      "adExtensions": "Ad Extensions",
      "keyword": "Keyword",
      "matchType": "Match Type",
      "trackingTemplateUrl": "Tracking Template Url",
      "finalUrls": "Final Urls",
      "finalMobileUrl": "FinalMobileUrl",
      "valid": "Valid Asset",
      "phoneNumber": "Phone Number",
      "countryCode": "Country Code",
      "displayPath1": "Display Path 1",
      "displayPath2": "Display Path 2",
      "hPosition1": "Headline Position 1",
      "hPosition2": "Headline Position 2",
      "hPosition3": "Headline Position 3",
      "dPosition1": "Description Position 1",
      "dPosition2": "Description Position 2",
      "businessName": "Business Name",
      "conversionAction": "Conversion Action",
      "name": "Name",
      "finalUrl": "Final URL",
      "longHeadline": "Long Headline",
      "appUrl": "App URL",
      "shortDescription": "Short Description",
      "headline": "Headline",
      "description": "Description",
      "logo": "Logo",
      "callToAction": "Call to Action",
      "lineItem": "Line Item",
      "searchThemes": "Search Themes",
      "firstPartyAudienceIds": "First Party Audience IDs",
      "inMarketInterestIds": "In-Market Interest IDs",
      "customAudienceIds": "Custom Audience IDs",
      "verificationUrl": "Verification URL",
      "youtubeVideos": "Youtube Videos",
      "CampaignName": "Campaign Name",
      "leadFormId": "Lead Form Id"
    };
    let items = ["headline", "longHeadline", "description", "marketingImage", "squareMarketingImage", "logo", "youtubeVideo", "squareLogo", "image", "video", "portraitMarketingImage", "card", "landscapeLogo"];
    let assetTypeReq = getAssetRequirements(this.config.campaign.type)
    if (this.config.campaign.type === adwordsEnums.campaignType["Demand Gen"]) {
      let demandGenAssetReq = {}
      for (let type in assetTypeReq) {
        if (assetTypeReq.hasOwnProperty(type)) {
          for (let asset in assetTypeReq[type]) {
            if (assetTypeReq[type].hasOwnProperty(asset)) {
              demandGenAssetReq[asset] = assetTypeReq[type][asset]
            }
          }
        }
      }
      assetTypeReq = demandGenAssetReq
    }
    for (let i = 0; i < items.length; i++) {
      if (assetTypeReq[items[i]]) {
        for (let j = 1; j <= assetTypeReq[items[i]].max; j++) {
          switch (items[i]) {
            case "headline":
              returnObj[items[i] + j] = "Headline " + j;
              break;
            case "longHeadline":
              returnObj[items[i] + j] = "Long Headline " + j;
              break;
            case "description":
              returnObj[items[i] + j] = "Description " + j;
              break;
            case "marketingImage":
              returnObj[items[i] + j] = "Marketing Image " + j;
              break;
            case "squareMarketingImage":
              returnObj[items[i] + j] = "Square Marketing Image " + j;
              break;
            case "logo":
              returnObj[items[i] + j] = "Logo " + j;
              break;
            case "youtubeVideo":
              returnObj[items[i] + j] = "Youtube Video " + j;
              break;
            case "video":
              returnObj[items[i] + j] = "Video " + j;
              break;
            case "squareLogo":
              returnObj[items[i] + j] = "Square Logo " + j;
              break;
            case "image":
              returnObj[items[i] + j] = "Image " + j;
              break;
            case "portraitMarketingImage":
              returnObj[items[i] + j] = "Portrait Marketing Image " + j;
              break;
            case "landscapeLogo":
              returnObj[items[i] + j] = "Landscape Logo " + j;
              break;
            case "card":
              returnObj[items[i] + j + 'MarketingImage'] = "Card " + j + " Marketing Image";
              returnObj[items[i] + j + 'SquareMarketingImage'] = "Card " + j + " Square Marketing Image";
              returnObj[items[i] + j + 'PortraitMarketingImage'] = "Card " + j + " Portrait Marketing Image";
              returnObj[items[i] + j + 'Headline'] = "Card " + j + " Headline";
              returnObj[items[i] + j + 'FinalURL'] = "Card " + j + " Final URL";
              returnObj[items[i] + j + 'CallToAction'] = "Card " + j + " Call To Action";
              break;
            default:
              break;
          }
        }
      }
    }

    return returnObj;
  }
}

export { AdwordsConfig };
