const destinationType = {
  DestinationUrl: 0,
  Page: 1,
  App: 2,
  Event: 3,
  Messenger: 4,
  SponsoredMessage: 5,
  WhatsApp: 6,
  InstagramDirect: 7,
  AppLinksAutomatic: 8,
  Facebook: 9,
  Undefined: 10,
  PhoneCall: 11,
  LeadForm: 12,
  Ad: 13,
  Post: 14,
  Video: 15,
  OnEvent: 16,
  OnPage: 17,
  LeadFormMessenger: 18
}

const objectives = {
  "Page Likes": 3,
  "Event Responses": 6,
  "Post Engagement": 7,
  "Conversions": 8,
  "Traffic": 11,
  "Video Views": 12,
  "Product Catalog Sales": 13,
  "App Installs": 15,
  "Brand Awareness": 16,
  "Reach": 18,
  "Lead Generation": 14,
  "Store Visits": 17,
  "Messages": 20,
  "Awareness": 21,
  "Engagement": 22,
  "Leads": 23,
  "Sales": 24,
  "Traffic Outcome": 25,
  "App Promotion": 26
}

const buyType = {
  "Auction": 1,
  "Reserved": 4
}

const bidStrategy = {
  "Lowest Cost": 1,
  "Lowest Cost with Bid Cap": 2,
  "Target Cost": 3,
  "Lowest Cost with Cost Cap": 4,
  "Lowest Cost with Min Roas": 5,
  "Unsupported": 6
}

const platforms = {
  Facebook: "Facebook",
  Instagram: "Instagram",
  AudienceNetwork: "Audience network",
  Messenger: "Messenger"
}

const positions = {
  Facebook: {
    Feed: "Feed",
    Search: "Search",
    RightHandColumn: "Right hand column",
    InstantArticle: "Instant article",
    MarketPlace: "Marketplace",
    Story: "Story",
    VideoFeeds: "Video feeds",
    InstreamVideo: "Instream video"
  },
  Instagram: {
    Stream: "Stream",
    Story: "Story",
    Explore: "Explore"
  },
  AudienceNetwork: {
    Classic: "Classic",
    InstreamVideo: "Instream video",
    RewardedVideo: "Rewarded video"
  },
  Messenger: {
    Home: "Home",
    SponsoredMessages: "Sponsored messages",
    Story: "Story"
  }
}

const dynamicAdVoice = {
  "1": "STORY_OWNER",
  "2": "DYNAMIC"
}

const targetingType = {
  PageSets: '1',
  GeoLocations: '2'
}

const placementCustomization = {
  categories: [
    {
      id: 1,
      title: "Feeds, In-stream Videos - Square (1:1)",
      checked: false,
      disabled: false,
      entityType: 1,
      expand: true,
      children: []
    },
    { id: 2,
      title: "Stories and Reels",
      checked: false,
      disabled: false,
      entityType: 1,
      expand: true,
      children: []
    },
    {
      id: 3,
      title: "Search results, Instant Articles",
      checked: false,
      disabled: false,
      entityType: 1,
      expand: true,
      children: []
    }
  ],
  categories_placements: {
    1: ["Facebook Feed", "Facebook Instream video", "Facebook Marketplace", "Facebook Video feeds", "Facebook Right hand column", "Instagram Feed", "Instagram Explore", "Messenger Home", "Instagram Stream"],
    2: ["Facebook Story", "Instagram Story", "Messenger Story", "Instagram Reels", "Facebook Reels"],
    3: ["Facebook Instant article", "Facebook Search", "Audience network Classic", "Audience network Instream video", "Messenger Sponsored messages", "Audience network Rewarded video"]
  },
  columns: [
    {
      id: 1,
      label: "Text",
      hotLabel: "HOT_Text",
      maxCount: 5,
      count: 1
    },
    {
      id: 2,
      label: "Image/Video",
      hotLabel: "HOT_Image/Video",
      maxCount: 1,
      count: 1
    },
    {
      id: 3,
      label: "Video",
      hotLabel: "HOT_Video",
      maxCount: 1,
      count: 1
    },
    {
      id: 4,
      label: "Headline",
      hotLabel: "HOT_Ad Title",
      maxCount: 5,
      count: 1
    },
    {
      id: 5,
      label: "Link",
      hotLabel: "HOT_Link",
      maxCount: 1,
      count: 1
    },
    {
      id: 6,
      label: "Primary Text",
      hotLabel: "HOT_Primary Text",
      maxCount: 5,
      count: 1
    },
    {
      id: 7,
      label: "Headline",
      hotLabel: "HOT_Headline",
      maxCount: 5,
      count: 1
    },
    {
      id: 8,
      label: "Video Thumbnail",
      hotLabel: "HOT_Video_Thumbnail",
      maxCount: 1,
      count: 1
    }
  ],
  multipleColumnIds: [1, 4, 6, 7]
}

const languageCustomization = {
  columns: [
    {
      id: 1,
      label: "Text",
      hotLabel: "HOT_Text",
      maxCount: 5,
      count: 1
    },
    {
      id: 2,
      label: "Image/Video",
      hotLabel: "HOT_Image/Video",
      maxCount: 1,
      count: 1
    },
    {
      id: 3,
      label: "Video",
      hotLabel: "HOT_Video",
      maxCount: 1,
      count: 1
    },
    {
      id: 4,
      label: "Headline",
      hotLabel: "HOT_Ad Title",
      maxCount: 5,
      count: 1
    },
    {
      id: 5,
      label: "Link",
      hotLabel: "HOT_Link",
      maxCount: 1,
      count: 1
    },
    {
      id: 6,
      label: "Primary Text",
      hotLabel: "HOT_Primary Text",
      maxCount: 5,
      count: 1
    },
    {
      id: 7,
      label: "Headline",
      hotLabel: "HOT_Headline",
      maxCount: 5,
      count: 1
    },
    {
      id: 8,
      label: "Description",
      hotLabel: "HOT_Description",
      maxCount: 5,
      count: 1
    }
  ],
  multipleColumnIds: []
}

const DynamicCreative = {
  columns: [
    {
      id: 1,
      label: "Primary Text",
      hotLabel: "HOT_Primary Text",
      maxCount: 4,
      count: 1
    },
    {
      id: 2,
      label: "Image/Video",
      hotLabel: "HOT_Image/Video",
      maxCount: 9,
      count: 1
    },
    {
      id: 3,
      label: "Headline",
      hotLabel: "HOT_Headline",
      maxCount: 4,
      count: 1
    },
    {
      id: 4,
      label: "Description",
      hotLabel: "HOT_Description",
      maxCount: 4,
      count: 1
    },
    {
      id: 5,
      label: "Call To Action",
      hotLabel: "HOT_Call To Action",
      maxCount: 1,
      count: 1
    }
  ],
  multipleColumnIds: [1, 2, 3, 4]
}

const optimizationGoalsEnum = {
  1: "None",
  2: "App Installs",
  3: "Clicks",
  4: "Engaged Users",
  5: "External",
  6: "Event Responses",
  8: "Link Clicks",
  9: "Offer Claims",
  10: "Conversions",
  12: "Page Likes",
  13: "Post Engagement",
  14: "Reach",
  15: "Social Impressions",
  16: "Video Views",
  17: "Lead Generation",
  18: "Brand Awareness",
  19: "App Downloads",
  20: "Landing Page Views",
  21: "Ad Recall Lift",
  22: "Value",
  23: "Conversations",
  24: "2 Second Continuous Video Views",
  25: "Store Visits",
  26: "Offsite Conversions",
  27: "Thruplay",
  28: "Unsupported",
  29: "App Events",
  30: "Calls",
  31: "App Installs And Offsite Conversions"
}

const billingEventsEnum = {
  1: "App Installs",
  2: "Clicks",
  3: "Impressions",
  4: "Link Clicks",
  5: "Offer Claims",
  6: "Page Likes",
  7: "Post Engagement",
  8: "Video Views",
  9: "None",
  10: "Mrc Video Views",
  11: "Completed Video Views",
  12: "Video Views 15s",
  13: "2 Second Continuous Video Views",
  14: "Store Visits",
  15: "Thruplay",
  16: "Unsupported"
}

const standardEventTypesEnum = {
  1: "Rate",
  2: "Tutorial Completion",
  3: "Contact",
  4: "Customize Product",
  5: "Donate",
  6: "Find Location",
  7: "Schedule",
  8: "Start Trial",
  9: "Submit Application",
  10: "Subscribe",
  11: "Add to Cart",
  12: "Add to Wishlist",
  13: "Initiated Checkout",
  14: "Add Payment Info",
  15: "Purchase",
  16: "Lead",
  17: "Complete Registration",
  18: "Content View",
  19: "Search",
  20: "Service Booking Request",
  21: "Messaging Conversation Started 7d",
  22: "Level Achieved",
  23: "Achievement Unlocked",
  24: "Spent Credits",
  25: "D2 Retention",
  26: "D7 Retention",
  27: "Other"
}

const dpaCreativeSettings = {
  "HOT_Frame Blending": ["Normal", "Multiply", "Lighten"],
  "HOT_Frame Position": ["Top", "Right", "Bottom", "Left", "Center", "Top Left", "Top Right", "Bottom Left", "Bottom Right"],
  "HOT_Catalogue Information Type": ["Price", "Strikethrough price", "Percentage off", "Free shipping"],
  "HOT_Catalogue Information Shape": ["Rectangle", "Pill", "Circle", "Triangle", "None"],
  "HOT_Catalogue Information Font": ["Droid serif regular", "Lato regular", "Noto sans regular", "Nunito sans bold", "Open sans bold", "Open sans condensed bold", "Pt serif bold", "Roboto condensed regular", "Roboto medium"],
  "HOT_Catalogue Information Position": ["Top Left", "Top Right", "Bottom Left", "Bottom Right"],
  "HOT_Catalogue Information Opacity": ["Solid", "Transparent"]
}

const dpaCreativeEditColumns = {
  "Frame": ["Frame Image", "Frame Blending", "Frame Opacity", "Frame Size", "Frame Position"],
  "Catalogue Information": ["Catalogue Information Type", "Catalogue Information Shape", "Catalogue Information Font", "Catalogue Information Shape Colour", "Catalogue Information Text Colour", "Catalogue Information Opacity", "Catalogue Information Position"]
}

const dpaCreativeEditRequiredCols = {
  "Frame": "Frame Image",
  "Catalogue Information": "Catalogue Information Type"
}

const dpaCreativeEditDefaultValues = {
  catalogueInformation: {
    overlay_shape: "rectangle",
    text_font: "open_sans_bold",
    shape_color: "DF0005",
    text_color: "FFFFFF",
    opacity: 100,
    overlay_position: "top_left"
  },
  frame: {
    blending_mode: "normal",
    opacity: 100,
    overlay_position: "center",
    scale: 100
  }
}

const locationType = {
  Standard: 1,
  Hyperlocal: 2,
  PageSets: 3
}

const locationTypes = {
  "Lat-Long": 1,
  "Postal Code": 2,
  "City": 3,
  "State": 4,
  "Country": 5
}

const locationMode = {
  Standard: "Standard",
  Hyperlocal: "Hyperlocal",
  PageSets: "PageSets"
}

const dpaFilterTypes = {
  inclusion: 1,
  exclusion: 2
}

const defaultOptimizationGoalsForAppInstall = { "auction": [29, 2, 8, 22] };

const optimizationGoalsForAutomatedAppAds = { "auction": [22, 31, 2, 26] };

const locationCategories = {
  "country": "countries",
  "city": "cities",
  "zip": "zips",
  "geo_market": "geo_markets",
  "region": "regions",
  "place": "places",
  "custom_location": "custom_locations",
  "neighborhood": "neighborhoods",
  "subcity": "subcities",
  "country_group": "country_groups"
}

const formatLabelsObj = {
  Country: "country",
  Geo_market: "geo_market",
  Region: "region",
  Country_group: "country_group",
  Custom_Location: "custom_location"
}

const targetLocationType = {
  "All": ['recent', 'home'],
  "Home": ['home'],
  "Recently Here": ['recent'],
  "Travelling Here": ['travel_in']
}

const RelationshipStatus = [
  { id: 1, name: "Single" },
  { id: 2, name: "In Relationship" },
  { id: 3, name: "Married" },
  { id: 4, name: "Engaged" },
  { id: 6, name: "Not Specified" },
  { id: 9, name: "In an open relationship" },
  { id: 10, name: "It's Complicated" },
  { id: 11, name: "Seprated" },
  { id: 12, name: "Divorced" },
  { id: 13, name: "Widowed" }
]

const Genders = {
  Male: [1],
  Female: [2],
  All: [1, 2]
}

const FacebookTargetLocationConst = {
  "locationId": Math.random(),
  "geoLocations": {
    "countries": [{
      "name": "India",
      "type": "country",
      "country_code": "IN",
      "country_name": "India",
      "supports_region": true,
      "supports_city": true,
      "key": "IN",
      "is_excluded": false
    }],
    "cities": [],
    "zips": [],
    "geo_markets": [],
    "regions": [],
    "places": [],
    "custom_locations": [],
    "neighborhoods": [],
    "subcities": [],
    "country_groups": []
  },
  "excludedGeoLocations": {
    "countries": [],
    "cities": [],
    "zips": [],
    "geo_markets": [],
    "regions": [],
    "places": [],
    "custom_locations": [],
    "neighborhoods": [],
    "subcities": [],
    "country_groups": []
  },
  "location_types": ["recent", "home"]
}

const FacebookFlexibleSpecConst = {
  "flexId": Math.random(),
  "gender": [1, 2],
  "minAge": 18,
  "maxAge": "No Max",
  "locales": [],
  "relationship_statuses": [],
  "flexible_spec": [{}],
  "exclusions": {},
  "custom_audiences": [],
  "excluded_custom_audiences": []
}

const SupportedTargetsProperties = [
  "geo_locations",
  "excluded_geo_locations",
  "age_min",
  "age_max",
  "genders",
  "locales",
  "relationship_statuses",
  "flexible_spec",
  "exclusions",
  "custom_audiences",
  "excluded_custom_audiences"
]

const storeFilterOptions = [
  { value: 'Advertiser', label: 'Advertiser ID' },
  { value: 'StoreCode', label: 'Store ID' }
]

const EducationSatus = [
  { id: 1, name: "At high school" },
  { id: 2, name: "At university" },
  { id: 3, name: "University graduate" },
  { id: 4, name: "High school leaver" },
  { id: 5, name: "Some university" },
  { id: 6, name: "Foundation degree" },
  { id: 7, name: "At university (postgraduate)" },
  { id: 8, name: "Some university (postgraduate)" },
  { id: 9, name: "Master's degree" },
  { id: 10, name: "Professional degree" },
  { id: 11, name: "Doctorate degree" },
  { id: 12, name: "Unspecified" },
  { id: 13, name: "Some high school" }
]

const filterConditions = {
  EQUAL: 1,
  GREATERTHAN: 2,
  GREATERTHANEQUAL: 3,
  LESSTHAN: 4,
  LESSTHANEQUAL: 5,
  LIKE: 6,
  IN: 7,
  NOTIN: 8
}

// New constants
const Campaignstatus = {
  "Active": 1,
  "Paused": 2
}

const summaryColumns = [
  "Account",
  "FB Page",
  "IG Page",
  "Objective",
  "Buy Type",
  "Tracking Settings",
  "Budget Setting",
  "Attribution Window"
]

const campaignColumns = [
  "Campaign",
  "Status",
  "Budget Type",
  "Budget"
]
const targetingColumns = ["Target Name", "Bids", "No. of Adgroups"]
const pagesAndCreativeColumn = [
  "Ad Copy Id",
  "Creative",
  "Landing Page",
  "Tragets",
  "Status"
]

const entityStatus = {
  "None": 0,
  "New": 1,
  "Success": 2,
  "Failed": 3,
  "Published": 4,
  "Skipped": 5,
  "Deleted": 6,
  "SuccessWithoutCreativeGroup": 7,
  "Edited": 8,
  "InReview": 9,
  "Rejected": 10
}

const AdProperties = {
  "HOT_Ad Name": "Name",
  "HOT_Landing Page URL": "LandingPageURL",
  "HOT_Jio Creative ID": "JioCreativeId",
  "HOT_Status": "Status",
  "HOT_Event": "TrackingURLs"
}

const campaignBudgetTypes = {
  "daily_budget": 1,
  "lifetime_budget": 2
}

const budgetTypeColumns = [
  "Campaign Name",
  "Status",
  "Schedule",
  "Budget Type",
  "Budget"
]

const locationColumns = [
  "Location",
  "No. of Adgroups"
]
const creativeColumns = [
  "Ad Copy Id",
  "Creative",
  "Landing Page",
  "Targets",
  "Status"
]
const creativeColumnsWidth = {
  "Creative": 430,
  "Landing Page": 200,
  "Targets": 200,
  "Status": 80,
  "Ad Copy Id": 120
}
const placementColumns = [
  "Platform",
  "Facebook Positions",
  "Instagram Positions",
  "Messenger Positions",
  "Audience Network Positions",
  "No. of Adgroups"
]

const landingPageColumns = [
  "URL",
  "Status"
]

const adTypes = {
  "single": 105,
  "carousel": 106,
  "collection": 107,
  "existing posts": 108,
  "singleCatalog": 109,
  "carouselCatalog": 110,
  "collectionCatalog": 111,
  "flexible": 124
}

const adStatus =
{
  "ACTIVE": 1,
  "PAUSED": 9,
  "DELETED": 3,
  "PENDING REVIEW": 4,
  "DISAPPROVED": 5,
  "PREAPPROVED": 12,
  "PENDING BILLING INFO": 7,
  "CAMPAIGN PAUSED": 10,
  "ARCHIVED": 11,
  "ADSET PAUSED": 8,
  "WITH ISSUES": 13,
  "IN PROCESS": 14
}

const adgroupStatus =
{
  "ACTIVE": 1,
  "PAUSED": 2,
  "DELETED": 3,
  "CAMPAIGN_PAUSED": 10,
  "ARCHIVED": 11,
  "PENDING_REVIEW": 12,
  "DISAPPROVED": 13,
  "PREAPPROVED": 14,
  "PENDING_BILLING_INFO": 15,
  "ADSET_PAUSED": 16,
  "WITH_ISSUES": 17,
  "IN_PROCESS": 18
}

const additionalSummaryColumns = [
  "Dayparting Schedule"
]

const days = [
  "Sun",
  "Mon",
  "Tue",
  "Wed",
  "Thur",
  "Fri",
  "Sat"
]

const adsetPlacements = {
  "Facebook": {
    key: "facebook",
    children: {
      "Feed": "feed",
      "Right hand column": "right_hand_column",
      "Marketplace": "marketplace",
      "Video feeds": "video_feeds",
      "Story": "story",
      "Search": "search",
      "Instream video": "instream_video",
      "Reels": "facebook_reels"
    }
  },
  "Instagram": {
    key: "instagram",
    children: {
      "Stream": "stream",
      "Story": "story",
      "Explore": "explore",
      "Reels": "reels"
    }
  },
  "Audience network": {
    key: "audience_network",
    children: {
      "Classic": "classic",
      "Instream video": "instream_video",
      "Rewarded video": "rewarded_video"
    }
  },
  "Messenger": {
    key: "messenger",
    children: {
      "Home": "messenger_home",
      "Sponsored messages": "sponsored_messages",
      "Story": "story"
    }
  }
};

const userOs = {
  All: 1,
  Feature: 2,
  Android: 3,
  iOS: 4
};

const osVersions = {
  android: [
    { version: "2.0", label: "2.0 Donut" },
    { version: "2.1", label: "2.1 Eclair" },
    { version: "2.2", label: "2.2 Froyo" },
    { version: "2.3", label: "2.3 Gingerbread" },
    { version: "3.0", label: "3.0 Honeycomb" },
    { version: "3.1", label: "3.1 Honeycomb" },
    { version: "3.2", label: "3.2 Honeycomb" },
    { version: "4.0", label: "4.0 Ice Cream Sandwich" },
    { version: "4.1", label: "4.1 Jelly Bean" },
    { version: "4.2", label: "4.2 Jelly Bean" },
    { version: "4.3", label: "4.3 Jelly Bean" },
    { version: "4.4", label: "4.4 KitKat" },
    { version: "5.0", label: "5.0 Lollipop" },
    { version: "5.1", label: "5.1 Lollipop" },
    { version: "6.0", label: "6.0 Marshmallow" },
    { version: "7.0", label: "7.0 Nougat" },
    { version: "7.1", label: "7.1 Nougat" },
    { version: "8.0", label: "8.0 Oreo" },
    { version: "8.1", label: "8.1 Oreo" },
    { version: "9.0", label: "9.0 Pie" }
  ],
  ios: [
    { version: "2.0", label: "2.0" },
    { version: "3.0", label: "3.0" },
    { version: "4.0", label: "4.0" },
    { version: "4.3", label: "4.3" },
    { version: "5.0", label: "5.0" },
    { version: "6.0", label: "6.0" },
    { version: "7.0", label: "7.0" },
    { version: "7.1", label: "7.1" },
    { version: "8.0", label: "8.0" },
    { version: "8.1", label: "8.1" },
    { version: "8.2", label: "8.2" },
    { version: "8.3", label: "8.3" },
    { version: "8.4", label: "8.4" },
    { version: "9.0", label: "9.0" },
    { version: "9.1", label: "9.1" },
    { version: "9.2", label: "9.1" },
    { version: "9.3", label: "9.1" },
    { version: "10.0", label: "10.0" },
    { version: "10.1", label: "10.1" },
    { version: "10.2", label: "10.2" },
    { version: "10.3", label: "10.3" },
    { version: "11.0", label: "11.0" },
    { version: "11.1", label: "11.1" },
    { version: "11.2", label: "11.2" },
    { version: "11.3", label: "11.3" },
    { version: "12.0", label: "12.0" },
    { version: "12.1", label: "12.1" },
    { version: "12.2", label: "12.2" },
    { version: "12.3", label: "12.3" },
    { version: "12.4", label: "12.4" },
    { version: "13.0", label: "13.0" },
    { version: "13.3", label: "13.3" }
  ]
};

const adgroupEditableFields = {
  StartDate: 1,
  EndDate: 2,
  Targeting: 3,
  OptimizationGoal: 4,
  BillingEvent: 5,
  ClickAttributionWindow: 6,
  ViewAttributionWindow: 7,
  CustomEventType: 8
};

const flexibleAdFields = [
  {
    key: "groups",
    label: "Groups",
    min: 1,
    max: 3,
    objectives: [objectives.Sales, objectives["App Promotion"]]
  },
  {
    key: "media",
    label: "Image/Video",
    min: 1,
    max: 10,
    objectives: [objectives.Sales, objectives["App Promotion"]]
  },
  {
    key: "primary_text",
    label: "Primary Text",
    min: 0,
    max: 5,
    objectives: [objectives.Sales, objectives["App Promotion"]]
  },
  {
    key: "headline",
    label: "Headline",
    min: 0,
    max: 5,
    objectives: [objectives.Sales, objectives["App Promotion"]]
  },
  {
    key: "description",
    label: "Description",
    min: 0,
    max: 5,
    objectives: [objectives.Sales]
  }
];

export {
  destinationType,
  objectives,
  buyType,
  bidStrategy,
  platforms,
  positions,
  dynamicAdVoice,
  targetingType,
  placementCustomization,
  optimizationGoalsEnum,
  billingEventsEnum,
  standardEventTypesEnum,
  dpaCreativeSettings,
  dpaCreativeEditColumns,
  dpaCreativeEditRequiredCols,
  dpaCreativeEditDefaultValues,
  locationType,
  locationTypes,
  locationMode,
  dpaFilterTypes,
  defaultOptimizationGoalsForAppInstall,
  optimizationGoalsForAutomatedAppAds,
  languageCustomization,
  DynamicCreative,
  storeFilterOptions,
  locationCategories,
  formatLabelsObj,
  targetLocationType,
  RelationshipStatus,
  Genders,
  FacebookFlexibleSpecConst,
  FacebookTargetLocationConst,
  SupportedTargetsProperties,
  EducationSatus,
  Campaignstatus,
  summaryColumns,
  campaignColumns,
  targetingColumns,
  pagesAndCreativeColumn,
  entityStatus,
  AdProperties,
  campaignBudgetTypes,
  budgetTypeColumns,
  locationColumns,
  creativeColumns,
  creativeColumnsWidth,
  placementColumns,
  landingPageColumns,
  adTypes,
  adStatus,
  adgroupStatus,
  additionalSummaryColumns,
  days,
  filterConditions,
  adsetPlacements,
  userOs,
  osVersions,
  adgroupEditableFields,
  flexibleAdFields
};
