<template>
    <i-modal ref="modal" class="modal_placement" :value="showModal"  width="50" scrollable :mask-closable="false" :closable="false">
        <div slot="header">
            <div class="modal-title"><b>Specifications</b></div>
            <div class="adtype-title">{{adTypeModalData.displayName}}</div>
        </div>
        <div class="spinner-box" v-show="loadingData">
                <i class="fa fa-3x fa-spinner fa-pulse spinner" aria-hidden="true"></i>
        </div>
        <div v-show="!loadingData">
          <div class="no-data-text" v-if="specifications.length <= 0"> No Data</div>
          <div class="form-body" v-else>
              <i-form ref="specifications" class="mainForm">
                <div v-for="(specGroup) in specifications" :key="specGroup.type">
                  <div class="specifications-header"><b>{{ specGroup.type }}</b></div>
                  <i-form-item
                  v-for="(spec) in specGroup.specifications" :key="specGroup.type + '-' + spec.name">
                    <i-row v-if="radioFields.includes(spec.key)">
                          <i-col span="3">
                            <label class="input-label">{{spec.name}}<span class='asteriskColor' v-if="spec.isMandatory">*</span></label>
                          </i-col>
                          <i-col span="14" offset="1">
                            <RadioGroup
                              v-model="spec.value"
                            >
                              <Radio v-for="option in radioOptions[spec.key]" :key="option" :label="option"></Radio>
                            </RadioGroup>
                          </i-col>
                    </i-row>
                    <i-row v-else-if="dropdownFields.includes(spec.key)">
                          <i-col span="3">
                            <label class="input-label">{{spec.name}}<span class='asteriskColor' v-if="spec.isMandatory">*</span></label>
                          </i-col>
                          <i-col span="14" offset="1">
                            <Select
                              v-model="spec.value"
                              filterable
                              @on-change="updateCardsSpecifications(spec)"
                            >
                              <Option v-for="option in dropDownOptions[spec.key]" :key="option" :value="option" :label="option">{{ option }}</Option>
                              </Select>
                          </i-col>
                    </i-row>
                    <i-row v-else>
                        <i-col span="3">
                          <label class="input-label">{{spec.name}}<span class='asteriskColor' v-if="spec.isMandatory">*</span></label>
                        </i-col>
                        <i-col span="14" offset="1">
                            <i-input type="text" v-model="spec.value" placeholder="Enter something..."
                            :class="{'error-border': spec.hasError}"
                            @on-blur="validateSpecificationItem(spec)"></i-input>
                        </i-col>
                    </i-row>
                    <div v-if="spec.children && spec.children.length">
                      <div v-for="child in spec.children">
                        <Divider orientation="left">{{ child.name }}</Divider>
                        <i-form-item v-for="subChild in child.values" :key="child.key + subChild.key">
                          <i-row v-if="radioFields.includes(subChild.key)">
                            <i-col span="3">
                              <label class="input-label">{{subChild.name}}<span class='asteriskColor' v-if="subChild.isMandatory">*</span></label>
                            </i-col>
                            <i-col span="14" offset="1">
                              <RadioGroup v-model="subChild.value">
                                <Radio v-for="option in radioOptions[subChild.key]" :key="option" :label="option"></Radio>
                              </RadioGroup>
                            </i-col>
                          </i-row>
                          <i-row v-else>
                            <i-col span="3">
                              <label class="input-label">{{subChild.name}}<span class='asteriskColor' v-if="subChild.isMandatory">*</span></label>
                            </i-col>
                            <i-col span="14" offset="1">
                                <i-input type="text" v-model="subChild.value" placeholder="Enter something..."
                                :class="{'error-border': subChild.hasError}"
                                @on-blur="validateSpecificationItem(subChild)"></i-input>
                            </i-col>
                          </i-row>
                        </i-form-item>
                      </div>
                    </div>
                  </i-form-item>
                </div>
              </i-form>
           </div>
        </div>
        <div slot="footer">
        <div>
          <button type="button" class="btn btn-white"
          @click="closeModal()" >Cancel</button>
          <button type="button" class="btn btn-success"
          :disabled="specifications.length <= 0"
          @click="saveSpecifications()">Save</button>
        </div>
      </div>
    </i-modal>
</template>

<script>
import { Modal, Form, Input, Row, Col, FormItem, RadioGroup, Radio, Select, Option, Divider } from "iview";
import { specificationsEnum } from "../../Inputs/Constants/SpecificationsEnum.js";

export default {
  components: {
    "i-modal": Modal,
    "i-form": Form,
    "i-form-item": FormItem,
    "i-input": Input,
    "i-col": Col,
    "i-row": Row,
    RadioGroup,
    Radio,
    Select,
    Option,
    Divider
  },
  props: {
    showModal: Boolean,
    specificationsData: Object,
    adTypeModalData: Object,
    view: String
  },
  data: function () {
    return {
      specifications: [],
      input: {},
      creative: {},
      loadingData: false,
      showActionModal: false,
      actionModalComment: "",
      actionModalType: "",
      creativeStatuses: [
        {
          label: "Submitted",
          value: 1
        },
        {
          label: "Accepted",
          value: 2
        },
        {
          label: "Rejected",
          value: 3
        },
        {
          label: "Flagged",
          value: 4
        }
      ],
      radioFields: ['Type'],
      radioOptions: {
        Type: ["Image", "Video"]
      },
      dropdownFields: ['CallToAction', 'Cards'],
      dropDownOptions: {
        CallToAction: ["Apply Now", "Book Now", "Book Travel", "Contact Us", "Download", "Get Quote", "Get Showtimes", "Install Mobile App", "Learn More", "Listen Now", "Message Page", "No Button", "Open Link", "Pay To Access", "Play Game", "See Menu", "Shop Now", "Sign Up", "Start Order", "Subscribe", "Use App", "Watch More", "Whatsapp Message"],
        Cards: [2, 3, 4, 5, 6, 7, 8, 9, 10]
      },
      tempCardsData: {},
      carouselAdKeys: specificationsEnum.carouselAdKeys
    }
  },
  watch: {
    showModal (value) {
      if (value) {
        this.setSpecificationsData();
      }
    }
  },
  computed: {
    modalTitle () {
      return "Specifications";
    }
  },
  methods: {
    setSpecificationsData () {
      var specifications = this.specificationsData;
      const specificationsObj = specifications;

      var adType = this.adTypeModalData;

      if (adType && adType.specifications && this.isValidJsonString(adType.specifications)) {
        const adTypeSpecifications = JSON.parse(adType.specifications);

        const resultantSpecifications = adTypeSpecifications.map(spec => {
          return {
            type: spec.type,
            specifications: spec.specifications.map(item => {
              const mappedItem = {
                key: item.key,
                name: item.displayName,
                isMandatory: item.isMandatory,
                hasError: false
              }
              if (item.key == this.carouselAdKeys.Cards) {
                mappedItem.value = specificationsObj && specificationsObj[spec.type][item.key] && typeof specificationsObj[spec.type][item.key] === 'object'
                  ? Object.keys(specificationsObj[spec.type][item.key]).length
                  : item.value;
                if (item.children) {
                  mappedItem.children = item.children.map(child => ({
                    key: child.key,
                    name: child.displayName,
                    values: child.value.map(v => ({
                      key: v.key,
                      name: v.displayName,
                      isMandatory: v.isMandatory,
                      hasError: false,
                      value: v.key === this.carouselAdKeys.Type
                        ? (specificationsObj && specificationsObj[spec.type][item.key] &&
                          specificationsObj[spec.type][item.key][child.key] &&
                          specificationsObj[spec.type][item.key][child.key][v.key]
                          ? specificationsObj[spec.type][item.key][child.key][v.key]
                          : v.value)
                        : (specificationsObj && specificationsObj[spec.type][item.key] &&
                          specificationsObj[spec.type][item.key][child.key] &&
                          specificationsObj[spec.type][item.key][child.key][v.key]
                          ? specificationsObj[spec.type][item.key][child.key][v.key]
                          : "")
                    }))
                  }));
                  if (mappedItem.value > item.value) {
                    this.updateCardsSpecifications(mappedItem, specificationsObj && specificationsObj[spec.type][item.key]);
                  }
                }
              } else {
                mappedItem.value = specificationsObj && specificationsObj[spec.type] && specificationsObj[spec.type][item.key] ? specificationsObj[spec.type][item.key] : item.value // Value from specifications based on type and key
              }
              return mappedItem;
            })
          };
        });

        this.specifications = [...resultantSpecifications];
      }
    },
    isValidJsonString (str) {
      if (typeof str !== 'string') return false;
      try {
        JSON.parse(str);
        return true;
      } catch {
        return false;
      }
    },
    closeModal () {
      this.specifications = [];
      this.$emit('saveSpecifications', 'closeOnly');
    },
    saveSpecifications () {
      let isValid = true;
      if (this.specifications.length <= 0) {
        isValid = false;
      }
      const validateSpecifications = (specifications) => {
        specifications.forEach((spec) => {
          if (spec.isMandatory && (!spec.value || (typeof spec.value === "string" && spec.value.trim() === ""))) {
            spec.hasError = true;
            isValid = false;
          } else if (spec.hasError) {
            isValid = false;
          } else {
            spec.hasError = false;
          }

          if (spec.children && spec.children.length > 0) {
            spec.children.forEach(child => {
              validateSpecifications(child.values);
            });
          }
        });
      };
      this.specifications.forEach((specGroup) => {
        validateSpecifications(specGroup.specifications);
      });
      if (isValid) {
        var specifications = this.specifications;
        const result = specifications.reduce((acc, { type, specifications }) => {
          acc[type] = specifications.reduce((specObj, { key, value, children }) => {
            if (key === this.carouselAdKeys.Cards && Array.isArray(children)) {
              specObj[key] = children.reduce((cardsObj, { key: cardKey, values }) => {
                cardsObj[cardKey] = values.reduce((valueObj, { key: valueKey, value }) => {
                  valueObj[valueKey] = value;
                  return valueObj;
                }, {});
                return cardsObj;
              }, {});
            } else {
              specObj[key] = value;
            }
            return specObj;
          }, {});
          return acc;
        }, {});

        this.$emit('saveSpecifications', result);
        this.specifications = [];
      }
    },
    validateSpecificationItem (spec) {
      if (spec.isMandatory &&
      (!spec.value || spec.value.trim() == "")) {
        spec.hasError = true;
      } else {
        spec.hasError = false;
      }
      if (spec.key == this.carouselAdKeys.Link || spec.key == this.carouselAdKeys.SeeMoreURL) {
        this.validateURL(spec);
      }
    },
    updateCardsSpecifications (spec, specData) {
      if (spec.key == this.carouselAdKeys.Cards) {
        if (spec.value > spec.children.length) {
          var cardsPresent = spec.children.length;
          for (let index = cardsPresent + 1; index <= spec.value; index++) {
            let cardName = "Card " + index.toString();
            let newCardData = JSON.parse(JSON.stringify(spec.children[0]));
            newCardData.key = cardName.replaceAll(' ', '');
            newCardData.name = cardName;
            newCardData.values.forEach(child => {
              child.value = specData && specData[newCardData.key] && specData[newCardData.key][child.key] ? specData[newCardData.key][child.key] : "";
              if (child.key === "Type" && child.value == "") {
                child.value = "Image";
              }
              child.hasError = false;
            });
            spec.children.push(newCardData);
          }
        } else if (spec.value < spec.children.length) {
          spec.children = spec.children.slice(0, spec.value);
        }
      }
    },
    validateURL (spec) {
      let url;
      var val = spec.value;
      if (spec.value.trim() == "") {
        return;
      }
      try {
        url = new URL(val);
      } catch (_) {
        spec.hasError = true;
        return false;
      }
      return url.protocol === "http:" || url.protocol === "https:";
    },
    validateRadioGroup (subChild) {
      if (!subChild.value) {
        subChild.hasError = true;
      } else {
        subChild.hasError = false;
      }
    }
  }
};
</script>

<style scoped>
.form-body {
  max-height: 400px;
  overflow: auto;
}

.no-data-text {
  text-align: center;
}

label {
  margin-left: 0px;
  float: right;
  color: #000000;
}

.asteriskColor {
  color: red;
}

.specifications-header {
  margin-left: 10px;
}

.btn-white {
  margin: 0px 10px;
}

.error-message {
  color: red;
  font-size: 12px;
  margin-top: 3px;
}

::v-deep .error-border .ivu-input {
  border: 1px solid red !important;
}

::v-deep .ivu-form-item {
  margin-bottom: 12px;
}

</style>
